export const createLinkHandler = () => {
  let lastRedirectAttempt = 0;
  const REDIRECT_COOLDOWN = 1000; // 1 秒冷卻時間
  let isFirstAttempt = true;

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const openUrlWithFallback = async (url) => {
    if (!url) return false;

    const now = Date.now();
    if (now - lastRedirectAttempt < REDIRECT_COOLDOWN) {
      return false;
    }
    lastRedirectAttempt = now;

    // 檢測是否為移動設備
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // 1. 改進的 window.open 方法
    const tryWindowOpen = async () => {
      return new Promise(async (resolve) => {
        let isOpened = false;
        const onBlur = () => {
          if (!isOpened) {
            isOpened = true;
            window.removeEventListener("blur", onBlur);
          }
        };
        window.addEventListener("blur", onBlur);

        try {
          // 對於移動設備的首次嘗試，使用不同的策略
          if (isMobile && isFirstAttempt) {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";

            // 創建一個包裝的點擊事件處理器
            const clickHandler = (e) => {
              e.preventDefault();
              window.removeEventListener("blur", onBlur);

              // 使用setTimeout確保在事件鏈中的正確時機
              setTimeout(() => {
                const newWindow = window.open(
                  url,
                  "_blank",
                  "noopener,noreferrer"
                );
                isOpened = !!newWindow;
                resolve(isOpened);
              }, 50);
            };

            link.addEventListener("click", clickHandler);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            isFirstAttempt = false;
          } else {
            // 非首次嘗試或非移動設備使用標準方法
            await delay(300);
            const newWindow = window.open(url, "_blank", "noopener,noreferrer");

            setTimeout(() => {
              if (!isOpened) {
                window.removeEventListener("blur", onBlur);
              }
              resolve(!!newWindow);
            }, 1000);
          }
        } catch (e) {
          console.log("window.open 失敗:", e);
          window.removeEventListener("blur", onBlur);
          resolve(false);
        }
      });
    };

    // 2. 改進的 Anchor Tag 方法
    const tryAnchorTag = async () => {
      return new Promise(async (resolve) => {
        let isNavigated = false;
        const onBlur = () => {
          if (!isNavigated) {
            isNavigated = true;
            window.removeEventListener("blur", onBlur);
          }
        };
        window.addEventListener("blur", onBlur);

        try {
          await delay(300);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.rel = "noopener noreferrer";

          // 為移動設備添加特殊處理
          if (isMobile) {
            link.setAttribute("data-mobile-redirect", "true");
          }

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setTimeout(() => {
            if (!isNavigated) {
              window.removeEventListener("blur", onBlur);
            }
            resolve(isNavigated);
          }, 1000);
        } catch (e) {
          console.log("Anchor Tag 失敗:", e);
          window.removeEventListener("blur", onBlur);
          resolve(false);
        }
      });
    };

    // 先嘗試 window.open
    let success = await tryWindowOpen();
    if (success) return true;

    // // 如果是移動設備且第一次嘗試失敗，立即重試一次
    // if (isMobile && isFirstAttempt) {
    //   await delay(100);
    //   success = await tryAnchorTag();
    //   if (success) return true;
    // }

    return false;
  };

  return {
    openUrlWithFallback,
  };
};
