import React, { useState } from "react";
import TabContent from "./components/TabContent";
import Footer from "./components/Footer";
import "./index.css";

function App() {
  // const brands = {
  //   蝦皮: [
  //     "蝦蝦果園澆水",
  //     "每日分享－家電館活動",
  //     "每日分享-蝦皮3C",
  //     "其他-檔期活動",
  //   ],
  //   Momo: ["Mo幣簽到分享任務", "轉盤分享"],
  //   // 'Brand3': ['Activity3-1', 'Activity3-2', 'Activity3-3']
  // };

  const brands = {
    Shopee: {
      蝦蝦果園澆水: "shopee1",
      "每日分享－家電館活動": "shopee2",
      "每日分享-蝦皮3C": "shopee3",
      "其他-檔期活動": "shopee4",
    },
    Momo: { Mo幣簽到分享任務: "momo1", "其他-轉盤分享": "momo2" },
  };
  const [activeTab, setActiveTab] = useState("Shopee");

  return (
    <div className="App">
      <div className="header">
        <img
          src="./LogoLinkHelper.png"
          alt="Web Logo"
          className="brand-logo"
          width="7%"
        />
      </div>
      <div className="body">
        <div className=""> </div>
        <div className="mainBody">
          <div className="tabs">
            {Object.keys(brands).map((brand) => (
              <button
                key={brand}
                className={`tablinks ${activeTab === brand ? "active" : ""}`}
                onClick={() => setActiveTab(brand)}
              >
                {brand}
              </button>
            ))}
          </div>
          {Object.keys(brands).map((brand) => (
            <TabContent
              key={brand}
              brand={brand}
              activities={brands[brand]}
              isActive={activeTab === brand}
            />
          ))}
        </div>
        <div className=""></div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
