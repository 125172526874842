import React, { useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import { createLinkHandler } from "./LinkHandler";

DOMPurify.setConfig({
  ADD_ATTR: ["target", "rel"],
});

const LinkInput = ({
  links,
  setLinks,
  baseurl,
  brand,
  activity,
  loadLinks,
  isValidURL,
  checkAndClearLocalStorage,
  reloadLocalLinks,
  updateLinkClickCount,
}) => {
  const linkHandler = createLinkHandler();
  const [currentInput, setCurrentInput] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);
  const inputRef = useRef(null);

  const disableZoom = () => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport)
      viewport.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
  };

  const enableZoom = () => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) viewport.content = "width=device-width, initial-scale=1.0";
  };

  const handleInputChange = (value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setCurrentInput(sanitizedValue);
    setShowSaveButton(isValidURL(sanitizedValue));
  };

  const saveLinkToLocalStorage = (newLink, index, brand, activity) => {
    const storedLinks = JSON.parse(localStorage.getItem("userLinks")) || {};

    if (!storedLinks[brand]) {
      storedLinks[brand] = {};
    }
    if (!storedLinks[brand][activity]) {
      storedLinks[brand][activity] = [];
    }

    const now = new Date();
    const expiryTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime();
    const formattedLink = {
      index: index,
      url: newLink.url,
      expiryTime: expiryTime,
    };
    storedLinks[brand][activity].push(formattedLink);
    localStorage.setItem("userLinks", JSON.stringify(storedLinks));
  };

  const saveLink = async (newLink, newIndex) => {
    try {
      await loadLinks();
      await axios.post(`${baseurl}/save-link`, {
        brand,
        activity,
        data: { [newIndex]: DOMPurify.sanitize(newLink.url) },
      });
      saveLinkToLocalStorage(newLink, newIndex, brand, activity);
      await loadLinks();
      await reloadLocalLinks();
      setCurrentInput("");
      setShowSaveButton(false);

      Swal.fire({
        title: "儲存成功!",
        text: "您的連結已成功儲存。",
        icon: "success",
        confirmButtonColor: "#e69b4c",
        confirmButtonText: "好的",
      });
    } catch (error) {
      console.error("儲存連結時發生錯誤:", error);
    }
  };
  const handleRedirect = async (sanitizedUrl = null, unclickedLink = null) => {
    try {
      // 獲取最新的連結狀態
      const latestLinks = await loadLinks();
      const latestUnclickedLink = latestLinks.find(
        (link) => link.clicked === 0 && link.url !== "" && isValidURL(link.url)
      );

      // 如果提供了特定的unclickedLink,檢查它是否仍然是最新的未點擊連結
      if (
        unclickedLink &&
        latestUnclickedLink &&
        latestUnclickedLink.url !== unclickedLink.url
      ) {
        await Swal.fire({
          title: "連結已更新",
          text: "連結已被其他使用者點擊，請重新確認。",
          icon: "info",
          confirmButtonColor: "#e69b4c",
          confirmButtonText: "好的",
        });
        showRedirectConfirmation();
        return false;
      }

      // 當URL被點擊時，先顯示確認框
      const confirmResult = await Swal.fire({
        title: "連結點擊確認",
        text: "您即將前往該連結，確定要繼續嗎？",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "確定前往並儲存",
        confirmButtonColor: "#e69b4c",
        cancelButtonText: "返回",
        cancelButtonColor: "gray",
      });

      if (!confirmResult.isConfirmed) {
        showRedirectConfirmation();
        return false;
      }

      // 確認後，更新點擊計數
      const linkToUpdate = unclickedLink || latestUnclickedLink;
      if (linkToUpdate) {
        await updateLinkClickCount(linkToUpdate.index);
      }

      // 儲存當前輸入的連結
      await handleSave();

      // 使用新的跳轉處理方法
      const urlToOpen =
        sanitizedUrl || (linkToUpdate ? linkToUpdate.url : null);
      if (urlToOpen) {
        const success = await linkHandler.openUrlWithFallback(urlToOpen);
        return success;
      }

      return true;
    } catch (error) {
      console.error("處理重定向時發生錯誤:", error);
      return false;
    }
  };

  const showRedirectConfirmation = async () => {
    try {
      const freshLinks = await loadLinks();
      const unclickedLink = freshLinks.find(
        (link) => link.clicked === 0 && link.url !== "" && isValidURL(link.url)
      );

      if (!unclickedLink) {
        await handleSave();
        return;
      }

      const sanitizedUrl = DOMPurify.sanitize(unclickedLink.url);

      await Swal.fire({
        title: "點擊以下連結並儲存：",
        html: `
          <style>
            @keyframes glow {
              0% {
                box-shadow: 0 0 5px rgba(230, 155, 76, 0.5),
                           0 0 10px rgba(230, 155, 76, 0.3),
                           0 0 15px rgba(230, 155, 76, 0.1);
                border-color: #e69b4c;
              }
              50% {
                box-shadow: 0 0 10px rgba(230, 155, 76, 0.8),
                           0 0 20px rgba(230, 155, 76, 0.6),
                           0 0 30px rgba(230, 155, 76, 0.4);
                border-color: #f4b06c;
              }
              100% {
                box-shadow: 0 0 5px rgba(230, 155, 76, 0.5),
                           0 0 10px rgba(230, 155, 76, 0.3),
                           0 0 15px rgba(230, 155, 76, 0.1);
                border-color: #e69b4c;
              }
            }

            @keyframes shine {
              0% {
                background-position: -100% 0;
              }
              100% {
                background-position: 200% 0;
              }
            }

            .url-container {
              position: relative;
              animation: glow 2s infinite ease-in-out;
            }

            .url-container::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                90deg,
                transparent,
                rgba(255, 255, 255, 0.6),
                transparent
              );
              background-size: 200% 100%;
              animation: shine 3s infinite linear;
              pointer-events: none;
            }

            .notice-text {
              color: #666;
              font-size: 0.9em;
              margin-top: 15px;
              padding: 10px;
              background-color: #f8f8f8;
              border-radius: 4px;
              border-left: 3px solid #e69b4c;
            }
          </style>
          <div class="url-container" style="
            margin: 20px auto;
            padding: 15px 20px;
            background-color: #fff8f0;
            border-radius: 8px;
            border: 2px solid #e69b4c;
            max-width: 90%;
            word-break: break-all;
            position: relative;
            overflow: hidden;
          ">
            <div
              class="swal-link-container"
              style="
                cursor: pointer;
                color: #e69b4c;
                font-size: 1.5em;
                text-decoration: underline;
                transition: color 0.3s ease;
                padding: 5px;
                position: relative;
                z-index: 1;
              "
              onmouseover="this.style.color='#d68c3d'"
              onmouseout="this.style.color='#e69b4c'"
              onclick="document.querySelector('.swal-link-click-trigger').click();"
            >
              ${sanitizedUrl}
            </div>
          </div>
          <div class="notice-text">
            ⚠️ 請等待連結跳轉完畢幫助他人分享成功唷
          </div>
          <button
            class="swal-link-click-trigger"
            style="display: none;"
          ></button>
        `,
        icon: "info",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "取消",
        cancelButtonColor: "gray",
        didOpen: () => {
          const triggerButton = document.querySelector(
            ".swal-link-click-trigger"
          );
          triggerButton.addEventListener("click", () =>
            handleRedirect(sanitizedUrl, unclickedLink)
          );
        },
        willClose: () => {
          const triggerButton = document.querySelector(
            ".swal-link-click-trigger"
          );
          if (triggerButton) {
            triggerButton.removeEventListener("click", () =>
              handleRedirect(sanitizedUrl, unclickedLink)
            );
          }
        },
      });
    } catch (error) {
      console.error("載入連結時發生錯誤:", error);
    }
  };

  const handleSave = async () => {
    const freshLinks = await loadLinks();
    const newLink = { url: DOMPurify.sanitize(currentInput), clicked: 0 };
    const newIndex = Math.max(...freshLinks.map((link) => link.index), 0) + 1;
    await saveLink(newLink, newIndex);
  };

  const handlePaste = async () => {
    try {
      // 首先檢查是否支援新版 Clipboard API
      if (navigator.clipboard && window.isSecureContext) {
        try {
          const text = await navigator.clipboard.readText();
          const cleanedUrl = extractValidURL(text);
          handleInputChange(cleanedUrl);
          return;
        } catch (err) {
          console.log(
            "Clipboard API failed, falling back to execCommand:",
            err
          );
        }
      }

      // 如果新版 API 不支援或失敗，嘗試使用 execCommand
      if (document.queryCommandSupported("paste")) {
        inputRef.current.focus();
        const success = document.execCommand("paste");
        if (success) {
          // 等待一個微小的延遲以確保內容被貼上
          setTimeout(() => {
            const cleanedUrl = extractValidURL(inputRef.current.value);
            handleInputChange(cleanedUrl);
          }, 100);
          return;
        }
      }

      // 如果上述方法都失敗，顯示手動貼上提示
      fallbackPaste();
    } catch (err) {
      console.error("剪貼簿操作失敗:", err);
      fallbackPaste();
    }
  };

  const fallbackPaste = () => {
    inputRef.current.focus();
    Swal.fire({
      title: "請手動貼上",
      text: "您的設備不支持自動貼上。請手動長按輸入框並選擇貼上。",
      icon: "info",
      confirmButtonColor: "#e69b4c",
      confirmButtonText: "好的",
    });
  };

  const extractValidURL = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);
    return matches && matches[0] ? DOMPurify.sanitize(matches[0]) : "";
  };

  return (
    <div className="input-row">
      <div className="hint">
        <p>儲存連結時將會自動跳轉至他人連結，若為第一位使用者將直接儲存!</p>
      </div>
      <input
        type="url"
        ref={inputRef}
        value={currentInput}
        placeholder="貼上您的分享連結"
        onChange={(e) => handleInputChange(e.target.value)}
        onFocus={disableZoom}
        onBlur={enableZoom}
      />
      <img
        src="/paste.svg"
        alt="Paste"
        className="paste-icon"
        onClick={handlePaste}
        style={{ cursor: "pointer" }}
      />
      {showSaveButton && (
        <span
          className="save-icon-wrapper"
          onClick={() => showRedirectConfirmation()}
        >
          <img src="/square-ok.svg" alt="Save" className="save-icon" />
        </span>
      )}
      {!showSaveButton && currentInput !== "" && (
        <div className="error-hint">請輸入正確網域的連結！</div>
      )}
    </div>
  );
};

export default LinkInput;
