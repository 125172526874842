import React from "react";

const Shopee = () => (
  <div>
    <span>
      <h3>📍 佈告欄：</h3>
    </span>
    <div>
      <p>
        <b> ❥ 限制網址開頭: https://shp.ee/ </b>
      </p>
      <p>
        <b> ❥ 每日 00:00 將會把資料清除</b>
      </p>
      <p>
        <b>❥ 可能需要透過手機 App 開啟才能成功幫助他人</b>
      </p>
      <p>
        <b>ps. 記得需等待 APP 完整跳轉成功哦～</b>
      </p>
    </div>
  </div>
);

const Momo = () => (
  <div>
    <span>
      <h3>📍 佈告欄：</h3>
    </span>
    <div>
      <p>
        <b> ❥ 限制網址開頭: https://www.momoshop.com.tw/</b>
      </p>
      <p>
        <b> ❥ 每日 00:00 將會把資料清除</b>
      </p>
      <p>
        <b>❥ 可能需要透過手機 App 開啟才能成功幫助他人</b>
      </p>
      <p>
        <b>ps. 記得需等待 APP 完整跳轉成功哦～</b>
      </p>
    </div>
  </div>
);

export { Shopee, Momo };
