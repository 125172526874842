import React from "react";

const DonateModal = ({ setShowModal }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={setShowModal}>
          &times;
        </span>

        <h3>若本平台有幫助到您的話</h3>
        <h3>可以考慮贊助我一杯咖啡的費用來維護伺服器 ☕️</h3>
        <span>請掃描以下 QR code 來進行贊助：</span>
        <div className="qr-code-container">
          <div className="qr-code-item">
            <img src="/JKOPay.jpg" alt="JKOPay" className="qr-code" />
            <p>
              <b>街口支付</b>
            </p>
          </div>
        </div>
        <h3>感謝您共同維護伺服器！</h3>
      </div>
    </div>
  );
};

export default DonateModal;
