import React from "react";
import Swal from "sweetalert2";
import { Tooltip } from "react-tooltip";
import DOMPurify from "dompurify";
import { createLinkHandler } from "./LinkHandler";

const LinkWait = ({
  links,
  showPendingLinks,
  toggleLinks,
  updateLinkClickCount,
  reloadLinks,
}) => {
  const linkHandler = createLinkHandler();
  const pendingLinks = links.filter((link) => link.clicked === 0);

  const handleToggleLinks = async () => {
    if (!showPendingLinks) {
      await reloadLinks();
    }
    toggleLinks();
  };

  const handleLinkClick = async (link) => {
    const sanitizedUrl = DOMPurify.sanitize(link.url);
    const result = await Swal.fire({
      title: "連結點擊確認",
      text: "您確定要幫忙點擊此連結嗎？",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e69b4c",
      confirmButtonText: "確認並前往",
      cancelButtonText: "取消",
    });

    if (result.isConfirmed) {
      await linkHandler.openUrlWithFallback(sanitizedUrl);
      await updateLinkClickCount(link.index);
      reloadLinks();
    }
  };

  return (
    <div>
      <div className="link-status-fold">
        <span
          className="status-fold-button"
          onClick={handleToggleLinks}
          data-tooltip-id="link-wait-expand-tooltip"
          data-tooltip-content="來看看還有沒有人沒被點到 👀"
        >
          {showPendingLinks ? "▲ 隱藏待點擊連結" : "▼ 顯示待點擊連結"}
        </span>
        <span className="status-refresh" onClick={reloadLinks}>
          ⟳
        </span>
      </div>
      <div
        className={`link-waitting ${
          showPendingLinks ? "expanded" : "collapsed"
        }`}
      >
        {showPendingLinks && pendingLinks.length > 0
          ? pendingLinks.map((link, index) => (
              <div key={index} className="link-status-item">
                <span className="link-status unclick">● 待點擊</span>
                <span
                  data-tooltip-id="link-wait-tooltip"
                  data-tooltip-content="幫忙點，功德+1 🌝"
                  onClick={() => handleLinkClick(link)}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {link.url}
                </span>
              </div>
            ))
          : showPendingLinks && (
              <div className="link-status-item">
                <span className="linkWaitWord">太好了，沒有待點擊的連結</span>
              </div>
            )}
      </div>
      <Tooltip id="link-wait-expand-tooltip" className="linktooltip" />
      <Tooltip id="link-wait-tooltip" className="linktooltip" />
    </div>
  );
};

export default LinkWait;
