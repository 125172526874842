import React, { useState, useEffect } from "react";
import axios from "axios";
import LinkInput from "./LinkInput";
import LinkStatus from "./LinkStatus";
import LinkHistory from "./LinkHistory";
import LinkWait from "./LinkWait";

const baseurl = "/api";

function Activity({ brand, activity, isActive }) {
  const [links, setLinks] = useState([
    { index: 1, url: "", clicked: 0, error: false },
  ]);
  const [canPaste, setCanPaste] = useState(true);
  const [showStatusLinks, setShowStatusLinks] = useState(false);
  const [showAllLinks, setShowAllLinks] = useState(false);
  const [linksLoaded, setLinksLoaded] = useState(false);
  const [localLinks, setLocalLinks] = useState([]);
  const [showPendingLinks, setShowPendingLinks] = useState(true);

  useEffect(() => {
    if (isActive) {
      loadLinks();
    } else {
      resetState();
    }
  }, [isActive, brand, activity]);

  const loadLinks = async () => {
    try {
      const response = await axios.get(`${baseurl}/load-links`, {
        params: { brand, activity },
      });
      const data = response.data;
      const loadedLinks = Object.keys(data).map((key) => ({
        index: parseInt(key),
        url: data[key].url,
        clicked: data[key].clicked,
        error: false,
      }));
      setLinks(loadedLinks);
      checkAndClearLocalStorage();
      return loadedLinks;
    } catch (error) {
      console.error("載入連結時發生錯誤:", error);
      return [];
    }
  };

  useEffect(() => {
    if (linksLoaded) {
      // Perform any actions that depend on links being loaded
      console.log("Links:", links);
    }
  }, [links, linksLoaded]);

  const resetState = () => {
    setLinks([{ index: 1, url: "", clicked: 0, error: false }]);
    setCanPaste(true);
    setLinksLoaded(false); // Reset the linksLoaded state
  };

  const isValidURL = (string) => {
    const urlRegex = new RegExp(
      // eslint-disable-next-line
      `^(https:\/\/(shp\\.ee|www\\.momoshop\\.com\\.tw)\/)`
    );
    const urlMatch = string.match(urlRegex);
    if (brand === "Shopee") {
      return urlMatch && urlMatch[0] === "https://shp.ee/";
    } else if (brand === "Momo") {
      return urlMatch && urlMatch[0] === "https://www.momoshop.com.tw/";
    } else {
      return false;
    }
  };

  const checkAndClearLocalStorage = () => {
    const storedLinks = JSON.parse(localStorage.getItem("userLinks")) || {};
    const currentTime = new Date().getTime();
    let hasChanges = false;

    for (const brand in storedLinks) {
      for (const activity in storedLinks[brand]) {
        // 過濾掉過期的連結，僅移除過期的 index
        const filteredLinks = storedLinks[brand][activity].filter(
          (link) => link.expiryTime > currentTime
        );

        // 檢查是否有任何連結被移除
        if (filteredLinks.length !== storedLinks[brand][activity].length) {
          storedLinks[brand][activity] = filteredLinks;
          hasChanges = true;
        }
      }
    }

    // 如果有任何更改，更新 localStorage
    if (hasChanges) {
      localStorage.setItem("userLinks", JSON.stringify(storedLinks));
    }
  };

  const reloadLocalLinks = async () => {
    // 呼叫傳入的 loadLinks 方法來獲取最新連結
    const latestLinks = await loadLinks();

    // 根據 localStorage 中的 index 去最新資料中找出 clicked 狀態
    const storedLinks = JSON.parse(localStorage.getItem("userLinks")) || {};
    const brandLinks = storedLinks[brand]
      ? storedLinks[brand][activity] || []
      : [];

    const updatedLinks = brandLinks.map((storedLink) => {
      const serverLink = latestLinks.find(
        (link) => link.index === storedLink.index
      );
      return {
        ...storedLink,
        clicked: serverLink ? serverLink.clicked : 0, // 如果未找到匹配，默認為 0
      };
    });

    setLocalLinks(updatedLinks);
  };

  const updateLinkClickCount = async (index) => {
    try {
      await axios.post(`${baseurl}/update-link`, {
        brand,
        activity,
        index,
      });

      setLinks(
        links.map((link) =>
          link.index === index ? { ...link, clicked: link.clicked + 1 } : link
        )
      );
    } catch (error) {
      console.error("更新點擊數時發生錯誤:", error);
      throw error;
    }
  };

  return isActive ? (
    <div className="activity">
      <LinkInput
        links={links}
        setLinks={setLinks}
        baseurl={baseurl}
        canPaste={canPaste}
        brand={brand}
        activity={activity}
        loadLinks={loadLinks}
        isValidURL={isValidURL}
        checkAndClearLocalStorage={checkAndClearLocalStorage}
        reloadLocalLinks={reloadLocalLinks}
        updateLinkClickCount={updateLinkClickCount}
      />
      <LinkStatus
        links={links}
        brand={brand}
        activity={activity}
        showStatusLinks={showStatusLinks}
        toggleLinks={() => setShowStatusLinks(!showStatusLinks)}
        checkAndClearLocalStorage={checkAndClearLocalStorage}
        loadLinks={loadLinks}
        localLinks={localLinks}
        reloadLocalLinks={reloadLocalLinks}
      />
      <LinkHistory
        links={links}
        showAllLinks={showAllLinks}
        toggleLinks={() => setShowAllLinks(!showAllLinks)}
        loadLinks={loadLinks}
      />
      <LinkWait
        links={links}
        showPendingLinks={showPendingLinks}
        toggleLinks={() => setShowPendingLinks(!showPendingLinks)}
        updateLinkClickCount={updateLinkClickCount}
        reloadLinks={loadLinks}
      />
    </div>
  ) : null;
}

export default Activity;
