import React from "react";
import { Tooltip } from "react-tooltip";

const LinkStatus = ({
  links,
  brand,
  activity,
  showStatusLinks,
  toggleLinks,
  loadLinks,
  localLinks,
  reloadLocalLinks,
}) => {
  const handleToggleLinks = async () => {
    if (!showStatusLinks) {
      // 如果連結清單目前是隱藏狀態，使用者點擊展開時觸發重新載入連結
      await reloadLocalLinks();
    }

    // 切換連結顯示狀態
    toggleLinks();
  };

  return (
    <div>
      <div className="link-status-fold">
        <span className="status-fold-button" onClick={handleToggleLinks}>
          {showStatusLinks
            ? "▲ 隱藏我的連結"
            : "▼ 顯示我貼上的連結 (只顯示最新五筆)"}
        </span>
        <span className="status-refresh" onClick={reloadLocalLinks}>
          {" "}
          ⟳
        </span>
      </div>
      <div
        className={`link-history ${showStatusLinks ? "expanded" : "collapsed"}`}
      >
        {localLinks.slice(-5).map((link, index) => (
          <div key={index} className="link-status-item">
            <span
              className={`link-status ${
                link.clicked > 0 ? "clicked" : "unclick"
              }`}
            >
              {link.clicked > 0 ? "● 已點擊" : "● 待點擊"}
            </span>
            <span
              data-tooltip-id="link-tooltip"
              data-tooltip-content={link.url}
            >
              {link.url}
            </span>
          </div>
        ))}
      </div>
      <Tooltip id="link-tooltip" className="linktooltip" />
    </div>
  );
};

export default LinkStatus;
