import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import witchCat from "./lotties/witchCat.json";
import shinyRain from "./lotties/shinyRain.json";

const MarqueeAnimation = ({ isModalOpen, onAnimationComplete }) => {
  const [animationState, setAnimationState] = useState("initial");
  const [text, setText] = useState("");

  useEffect(() => {
    if (isModalOpen) {
      setAnimationState("modalOpen");
      setText("好運貓貓已出現!!");
    } else if (animationState === "modalOpen") {
      setAnimationState("closing");
      setText("您已被好運貓貓造訪");
    }
  }, [isModalOpen, animationState]);

  const handleAnimationComplete = () => {
    if (animationState === "closing") {
      setAnimationState("hidden");
      onAnimationComplete();
    }
  };

  const witchCatSetting = {
    loop: true,
    autoplay: true,
    animationData: witchCat,
  };
  const shinyRainSetting = {
    loop: true,
    autoplay: true,
    animationData: shinyRain,
  };

  if (animationState === "hidden") return null;

  return (
    <div className={`marquee-container ${animationState}`}>
      <div className="marquee-animation top-animation">
        <Lottie
          options={witchCatSetting}
          height={400}
          width={400}
          onComplete={handleAnimationComplete}
          keepLastFrame
        />
      </div>
      <div className="marquee-animation bottom-animation">
        <Lottie
          options={shinyRainSetting}
          height={400}
          width={400}
          onComplete={handleAnimationComplete}
          keepLastFrame
        />
      </div>
      <div className="marquee-animation text-animation">
        <div className="marquee-animation marquee-word">
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
};

export default MarqueeAnimation;
